/**
 * Manufacturing
 * Manufacturing
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Created, 1 = Ordered, 2 = Assembled, 3 = Tested, 4 = Programmed, 5 = Active, 6 = Inactive
 */
export enum LifeCycleStateEnum {
    Created = 0,
    Ordered = 1,
    Assembled = 2,
    Tested = 3,
    Programmed = 4,
    Active = 5,
    Inactive = 6
}

